import { connect } from 'react-redux'

import Layout from './layout'

import {
  checkPrePopulatedData,
  checkUrlCampaign,
  checkUrlSrc,
} from '../../reducers/captive'

export default connect((state) => state, {
  checkPrePopulatedData,
  checkUrlCampaign,
  checkUrlSrc,
})(Layout)
