import React, { Component } from 'react'
import MaterialModal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'

import terms from './terms-of-service'
import privacy from './privacy'
import partners from './partners'

import './modal.scss'

// make references to the components by type
const Components = {
  terms,
  privacy,
  partners,
}

class Modal extends Component {
  constructor(props) {
    super(props)
    this.state = { placement: false }

    this.handleClose = this.handleClose.bind(this)
  }

  handleClose = () => {
    this.props.toggleModal(this.props.type)
  }

  render = () => {
    let Component = Components[this.props.type]
    let openState = this.props.modals[this.props.type]
      ? this.props.modals[this.props.type]
      : false
    return (
      <MaterialModal
        open={openState}
        onClose={this.handleClose}
        classes={{ root: 'modal' }}
      >
        <div className="container">
          <div className="content">
            <Component />
          </div>
        </div>
      </MaterialModal>
    )
  }
}

Modal.propTypes = {
  type: PropTypes.string,
}

Modal.defaultProps = {
  type: `terms`,
}

export default Modal
