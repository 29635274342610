import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import logo from '../../images/logo-white.svg'

class Footer extends React.Component {
  constructor(props) {
    super(props)

    this.handleFooterLink = this.handleFooterLink.bind(this)
  }

  handleFooterLink = (e, type) => {
    e.preventDefault()
    this.props.toggleModal(type)
  }

  render = () => {
    const { phone } = this.props
    return (
      <footer id="footer" className="bg-dark">
        <div className="container">
          <strong className="logo pb3-l pb4 pl3 pl0-m">
            <img src={logo} alt="AutoInsuranceCompanion.com" />
          </strong>
        </div>
        <div className="container">
          <div className="cta-holder">
            <h4 className="t-orange-light">Still have questions?</h4>
            <p>Call now to speak with a Licensed insurance agent.</p>
            <a href={`tel:${phone}`} className="btn with-icon">
              <span className="icon-phone" /> <span className="text">{phone}</span>
            </a>
          </div>
          <div className="menu-holder">
            <ul className="footer-menu">
              <li>
                <span className="copyright">
                  &copy; 2022 <Link to="/">Auto Insurance Companion</Link>
                </span>
              </li>
              <li>
                <Link onClick={(e) => this.handleFooterLink(e, 'terms')} to="/">
                  Terms of Services
                </Link>
              </li>
              <li>
                <Link onClick={(e) => this.handleFooterLink(e, 'privacy')} to="/">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <a target="_blank" href="/ccpa">
                  CCPA
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = {
  phone: PropTypes.string,
}

Footer.defaultProps = {
  phone: ``,
}

export default Footer
